<template>
  <b-form-group
    :label="title"
    :invalid-feedback="failed"
    :state="checkState"
  >
    <datetime
      :disabled="readOnly"
      :format="dateFormat"
      v-model="selectDate"
      :phrases="phrases"
      input-class="form-control"
    >
      <template #after>
        <div
          v-if="hasErase"
          class="vdatetime__button"
          @click="eraseValue"
        >
          <font-awesome-icon
            icon="close"
            class="fa-fw"
          />
        </div>
      </template>
    </datetime>
  </b-form-group>
</template>

<script>
import { isNotEmpty } from '../../utils/validators';

export default {
  name: 'FieldCalendar',
  model: {
    prop: 'currentDate',
    event: 'input',
  },
  props: {
    currentDate: String,
    title: String,
    readOnly: Boolean,
    errorMessage: String,
    hasErase: Boolean,
    state: Boolean,
  },
  data() {
    return {
      dateFormat: 'dd/MM/yyyy',
      phrases: { ok: 'Ok', cancel: 'Annulla' },
    };
  },
  computed: {
    failed() {
      return this.errorMessage || 'Il campo è obbligatorio';
    },
    selectDate: {
      get() {
        return this.currentDate;
      },
      set(date) {
        this.$emit('input', date);
      },
    },
    checkState() {
      if (isNotEmpty(this.currentDate)) return this.state;
      return null;
    },
  },
  mounted() {
    // console.log('this.state', this.title, this.state);
  },
  methods: {
    updateSelf(name) {
      this.$emit('input', name);
    },
    eraseValue() {
      if (!this.readOnly) this.$emit('input', null);
    },
  },
};
</script>

<style scoped>

</style>
